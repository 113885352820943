:root {
  --theme-bg-color: #f4f6f4;
  --theme-color: #000;
  --overlay-bg: rgb(255 255 255 / 30%);
  --border-color: rgb(255 255 255 / 35%);
  --scrollbar-bg: rgb(255 253 253 / 57%);
  --navbar-color: white;
  --default-color: #1b9f36;
}

// sidebar

$submenu-bg-color: #f4f6f4;
$submenu-bg-color-collapsed: white;

$sidebar-bg-color: white;
$highlight-color: #1b9f36;

$icon-bg-color: transparent;

$breakpoint-md: 820px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none;
}

.collapse-sidebar {
  position: fixed;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 2;
  top: 20px;
  color: #adadad;
  &:focus {
    outline: none;
  }
}

// navbar

.navbar-light {
  background: var(--navbar-color);
}

// wrapper

.wrapper {
  background-color: var(--theme-bg-color);
  height: 100vh;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  font-size: 15px;
  font-weight: 500;
}

.wrapper {
  flex-grow: 1;
  overflow: hidden;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-wrapper {
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  color: var(--theme-color);
  padding: 56px 20px 0px 20px;
  height: 100%;
  margin-left: 0;
  overflow: auto;
  background-color: var(--theme-bg-color);
  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-radius: 14px;
    padding: 20px 40px;
    @media screen and (max-width: 415px) {
      padding: 20px;
    }
  }
  &.overlay {
    pointer-events: none;
    transition: 0.3s;
    background-color: var(--overlay-bg);
  }
}
// dashboard

// modal

.modal-title svg {
  margin-top: 5px;
  cursor: pointer;
  &:hover {
    color: #000;
  }
  color: rgb(127, 127, 127);

  height: '.7em';
  width: '.7em';
  float: right !important;
}

#react-confirm-alert .react-confirm-alert-overlay {
  z-index: 1051;
}
// diversos

button.btn-success:focus {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.search-label {
  width: 100%;
}

// form input file
#formSendFile {
  padding-left: 0.5em;
}

input[type='file'] {
  display: none;
}

// table

.row.react-bootstrap-table-pagination {
  display: flex;
  flex-wrap: nowrap;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
}

.dropdown-menu.show,
.dropdown-item {
  background: #f8f8f8;
  color: #000;
  &.btn:active {
    background-color: transparent;
  }
}

.switch-off.btn {
  padding-left: 12px;
}

.switch-on.btn {
  padding-right: 12px;
}
a.dropdown-item.active,
a.dropdown-item:active {
  background: transparent;
}
div.alert.alert-error {
  position: relative;
  padding: 1rem;
  margin-bottom: 0;
  color: #842029;
  background-color: #f8d7da;
  border: #f5c2c7;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #0f5132;
    background-color: #d1e7dd;
    border: #badbcc;
  }
}

div.alert.alert-light {
  position: relative;
  padding: 1rem;
  margin-bottom: 0;
  color: #636464;
  background-color: #fefefe;
  border: #fdfdfe;
  border-radius: 0.375rem;
}

div.alert.alert-dark {
  position: relative;
  padding: 1rem;
  margin-bottom: 0;
  color: #141619;
  background-color: #d3d3d4;
  border: #bcbebf;
  border-radius: 0.375rem;
}

div.alert.alert-secondary {
  position: relative;
  padding: 1rem;
  margin-bottom: 0;
  color: #41464b;
  background-color: #e2e3e5;
  border: #d3d6d8;
  border-radius: 0.375rem;
  &:hover {
    color: #0f5132;
    background-color: #d1e7dd;
    border: #badbcc;
  }
}

div.react-confirm-alert-button-group {
  justify-content: center;
}
